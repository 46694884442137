require('./bootstrap');

let showInformation = false

/**
 * Open information tab when click on icon arrow up
 */
document.getElementById('open-1').addEventListener('click', function() {
    openInfo()
})

/**
 * Open information tab when click on text information
 */
document.getElementById('open-2').addEventListener('click', function() {
    openInfo()
})

/**
 * When click on icon arrow down close information tab
 */
document.getElementById('close').addEventListener('click', function() {
    closeInfo()
})

/**
 * When click on main container close information tab
 */
document.getElementById('mainContainer').addEventListener('click', function() {
    closeInfo()
})

/**
 * Open information tab
 */
function openInfo() {
    document.getElementById('main').classList.add('show-information')
    setTimeout(() => {
        showInformation = true
        console.log('change status');
    }, 500);
}

/**
 * Close information tab
 */
function closeInfo() {
    if(showInformation) {
        document.getElementById('main').classList.remove('show-information')
        showInformation = false
    }
}
